import React from 'react';
import wishlistButtonStylable from './WishlistButton.st.css';
import wishlistIconButtonStyle from './WishlistIconButton.scss';
import {Button} from 'wix-ui-tpa/Button';
import {Heart} from 'wix-ui-tpa/dist/src/assets/icons';
import classNames from 'classnames';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {StatesButton} from 'wix-ui-tpa/StatesButton';
import {ActionStatus} from '@wix/wixstores-client-core/dist/es/src/constants';
import {BUTTON_STATES} from 'wix-ui-tpa/dist/src/components/StatesButton/constants';

export enum Mode {
  INLINE,
  FLOATING,
}

export interface WishlistButtonProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  mode: Mode;
}

@withGlobalProps
@withTranslations('globals.texts')
export class WishlistButton extends React.Component<WishlistButtonProps> {
  private get isFloatingMode() {
    const {mode} = this.props;

    return mode === Mode.FLOATING;
  }

  private readonly handleWishlistButtonClick = () => {
    const {handleWishlistButtonClick} = this.props.globals;

    handleWishlistButtonClick();
  };

  private getButtonAriaLabel() {
    const {
      t,
      globals: {productWasAddedToWishlist},
    } = this.props;

    return productWasAddedToWishlist ? t('sr.wishlist.remove.button') : t('sr.wishlist.button');
  }

  private renderIconButton() {
    const {
      globals: {productWasAddedToWishlist},
    } = this.props;

    const buttonClasses = classNames('wishlistButton', wishlistIconButtonStyle.wishlistIconButton, {
      [wishlistButtonStylable.wishlistButton]: !this.isFloatingMode,
      [wishlistButtonStylable.wishlistButtonFloatingMode]: this.isFloatingMode,
      [wishlistIconButtonStyle.floatingMode]: this.isFloatingMode,
    });
    const heartClass = productWasAddedToWishlist ? wishlistIconButtonStyle.filled : wishlistIconButtonStyle.empty;

    return (
      <Button
        onClick={this.handleWishlistButtonClick}
        className={buttonClasses}
        data-hook={'wishlist-button'}
        aria-label={this.getButtonAriaLabel()}
        aria-pressed={productWasAddedToWishlist}>
        <i className={wishlistIconButtonStyle.iconContainer}>
          <Heart
            className={classNames(wishlistIconButtonStyle.heartIcon, heartClass)}
            data-hook={'wishlist-button-icon'}
          />
        </i>
      </Button>
    );
  }

  private renderFullWidthButton() {
    const {
      t,
      globals: {productWasAddedToWishlist, wishlistActionStatus, resetWishlistStatus},
    } = this.props;

    const caption = productWasAddedToWishlist ? t('wishlist.remove.button') : t('wishlist.button');

    return (
      <StatesButton
        state={wishlistActionStatus === ActionStatus.SUCCESSFUL ? BUTTON_STATES.SUCCESS : BUTTON_STATES.IDLE}
        idleContent={caption}
        onNotificationEnd={() => resetWishlistStatus()}
        onClick={this.handleWishlistButtonClick}
        className={wishlistButtonStylable.wishlistFullWidthButton}
        disabled={false}
        fullWidth
        data-hook={'wishlist-states-button'}
      />
    );
  }

  private shouldRenderIconButton() {
    const {
      globals: {shouldShowAddToCartButton, shouldShowBuyNowButton},
    } = this.props;

    return shouldShowAddToCartButton || shouldShowBuyNowButton || this.isFloatingMode;
  }

  public render() {
    return this.shouldRenderIconButton() ? this.renderIconButton() : this.renderFullWidthButton();
  }
}
