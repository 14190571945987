import React, {Component} from 'react';
import {RadioButton} from 'wix-ui-core/radio-button';
import {
  withTranslations,
  IProvidedTranslationProps,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobalProps} from '../../../../providers/globalPropsProvider';
import s from './SubscriptionPlan.scss';
import {SubscriptionPlanFrequency} from '../../../../graphql/queries-schema';
import classNames from 'classnames';
import UncheckedRadioButtonIcon from '../../../../assets/uncheckedRadioButton.svg';
import checkedRadioButtonIcon from '../../../../assets/checkedRadioButton.svg';

export const enum SubscriptionPlanDataHooks {
  PLAN_ITEM = 'plan-item',
  NAME = 'plan-name',
  TAGLINE = 'plan-tagline',
  PRICE = 'plan-price',
  FREQUENCY = 'plan-frequency',
  DURATION = 'plan-duration',
  SELECTION = 'plan-selection',
  SELECTION_ICON = 'SELECTION_ICON',
}

export interface ISubscriptionPlan {
  id: string;
  name: string;
  tagline?: string;
  formattedPrice: string;
  frequency?: SubscriptionPlanFrequency;
  duration?: number;
  isOneTimePurchase: boolean;
}

export interface ISubscriptionPlanProps extends IProvidedTranslationProps {
  plan: ISubscriptionPlan;
  isSelected: boolean;
  className?: string;
  onClick?(): void;
}

@withGlobalProps
@withTranslations('globals.texts')
export class SubscriptionPlan extends Component<ISubscriptionPlanProps> {
  private readonly getDurationTranslation = () => {
    const {duration} = this.props.plan;
    return duration > 1 ? this.getPluralDurationTranslation() : this.getSingularDurationTranslation();
  };

  private readonly getSingularDurationTranslation = () => {
    const t = this.props.t;
    const frequency = this.props.plan.frequency;
    switch (frequency) {
      case 'DAY':
        return 'DAY_QA';
      case 'MONTH':
        return t('PRODUCT_PAGE_PLAN_DURATION_DESCRIPTION_SINGULAR', {
          frequencyUnitSingular: t('PRODUCT_PAGE_PLAN_MONTH'),
        });
      case 'WEEK':
        return t('PRODUCT_PAGE_PLAN_DURATION_DESCRIPTION_SINGULAR', {
          frequencyUnitSingular: t('PRODUCT_PAGE_PLAN_WEEK'),
        });
      case 'YEAR':
        return t('PRODUCT_PAGE_PLAN_DURATION_DESCRIPTION_SINGULAR', {
          frequencyUnitSingular: t('PRODUCT_PAGE_PLAN_YEAR'),
        });
    }
  };

  private readonly getPluralDurationTranslation = () => {
    const t = this.props.t;
    const {frequency, duration} = this.props.plan;

    switch (frequency) {
      case 'DAY':
        return 'DAYS_QA';
      case 'MONTH':
        return t('PRODUCT_PAGE_PLAN_DURATION_DESCRIPTION_PLURAL', {
          numberOfFrequencyUnits: duration,
          frequencyUnitPlural: t('PRODUCT_PAGE_PLAN_MONTHS'),
        });
      case 'WEEK':
        return t('PRODUCT_PAGE_PLAN_DURATION_DESCRIPTION_PLURAL', {
          numberOfFrequencyUnits: duration,
          frequencyUnitPlural: t('PRODUCT_PAGE_PLAN_WEEKS'),
        });
      case 'YEAR':
        return t('PRODUCT_PAGE_PLAN_DURATION_DESCRIPTION_PLURAL', {
          numberOfFrequencyUnits: duration,
          frequencyUnitPlural: t('PRODUCT_PAGE_PLAN_YEARS'),
        });
    }
  };

  private getFrequencyTranslation(): string {
    const t = this.props.t;
    const {frequency} = this.props.plan;

    switch (frequency) {
      case 'DAY':
        return 'DAYLY_QA';
      case 'MONTH':
        return t('PRODUCT_PAGE_PLAN_FREQUENCY_MONTHLY');
      case 'WEEK':
        return t('PRODUCT_PAGE_PLAN_FREQUENCY_WEEKLY');
      case 'YEAR':
        return t('PRODUCT_PAGE_PLAN_FREQUENCY_YEARLY');
    }
  }

  private readonly onClick = () => {
    const {onClick: onCompClicked} = this.props;
    onCompClicked && onCompClicked();
  };

  public render() {
    const {isSelected, className} = this.props;
    const {name, tagline, formattedPrice, frequency, duration} = this.props.plan;

    return (
      <div
        data-hook={SubscriptionPlanDataHooks.PLAN_ITEM}
        className={classNames(s.container, className)}
        onClick={this.onClick}>
        <span className={s.radioButton}>
          <RadioButton
            data-hook={SubscriptionPlanDataHooks.SELECTION}
            checked={isSelected}
            checkedIcon={
              <img
                data-hook={SubscriptionPlanDataHooks.SELECTION_ICON}
                src={checkedRadioButtonIcon}
                className={s.radioButtonIcon}
              />
            }
            uncheckedIcon={
              <img
                data-hook={SubscriptionPlanDataHooks.SELECTION_ICON}
                src={UncheckedRadioButtonIcon}
                className={s.radioButtonIcon}
              />
            }></RadioButton>
        </span>
        <div>
          <div data-hook={SubscriptionPlanDataHooks.NAME}>{name}</div>
          {!!tagline && tagline.trim() && (
            <div className={s.subtitle} data-hook={SubscriptionPlanDataHooks.TAGLINE}>
              {tagline}
            </div>
          )}
          <div>
            <span data-hook={SubscriptionPlanDataHooks.PRICE}>{formattedPrice}</span>
            {!!frequency && (
              <span className={s.frequency} data-hook={SubscriptionPlanDataHooks.FREQUENCY}>
                {this.getFrequencyTranslation()}
              </span>
            )}
            {!!duration && (
              <span className={s.duration} data-hook={SubscriptionPlanDataHooks.DURATION}>
                {this.getDurationTranslation()}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}
